import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";

import { TranslationEntry } from "src/app/models/translation-entry";

@Injectable({ providedIn: "root" })
export class TranslationResolver {
  constructor(private translateService: TranslateService) {}

  /**
   * Resolves the translation data for route navigation.
   *
   * @remarks
   * Fetches the translation for the current language from the
   * TranslateService.
   *
   * @returns An Observable that emits the translation data.
   */
  resolve(): Observable<TranslationEntry> {
    return this.translateService.getTranslation(
      this.translateService.currentLang
    ) as Observable<TranslationEntry>;
  }
}

/**
 * Swap client specific translations.
 *
 * @remarks
 * The input object contains key value pairs of translations for the
 * specific language. We loop through each key and check if there is
 * a client specific key. For eg: If the translation key is "dom_x" and
 * client_id is 50, we check if there is a translation "dom_x_50".
 * If there is client specific translation, we swap the value of "dom_x"
 * with the value of "dom_x_50".
 *
 * @param translations - Translations object containing key value pairs.
 * @param client_id - Client id of the user.
 * @returns - Object containing swapped client specific translations.
 */
export function swapClientTranslations(
  translations: { [key: string]: string },
  client_id: string
): { [key: string]: string } {
  if (!client_id) return translations;

  const trailingString = "_" + client_id;

  const swappedTranslations = {};

  if (translations)
    for (const [key, value] of Object.entries(translations)) {
      const swappableKey = key + trailingString;
      if (translations[swappableKey]) {
        swappedTranslations[key] = translations[swappableKey];
      } else {
        swappedTranslations[key] = value;
      }
    }

  return swappedTranslations;
}
