/** Enum for app data type to avoid magical strings. */
export enum AppDataType {
  APP_DATA = "appData",
  CLIENT_DATA = "clientData",
  CLIENT_REFERENCE = "clientReference",
}

/** Enum for operation type. */
export enum OperationType {
  EDITING = "editing",
  READONLY = "readonly",
  CREATING = "creating",
}

/** Enum for client reference status. */
export enum ClientReferenceStatus {
  ACTIVE = 1,
  SUBMITTED = 2,
  CLOSED = 3,
  DELETED = 4,
  FAILED = 5,
}

/** Enum for email type. */
export enum EmailType {
  SUBMIT = "submit",
  REQUEST_UNLOCK = "request_unlock",
}

/** Enum for the source of s-list invocation */
export enum ItemsListComponentSource {
  INVENTORY_ITEMS_BY_LOCATION = "inventoryItemsByLocation",
  UNASSIGNED_LOCATION_INVENTORY_ITEMS = "unassignedLocationInventoryItems",
}

/** Enum for privacy policy status. */
export enum PrivacyPolicyStatus {
  NOT_SEEN = 0,
  DECLINED = 1,
  ACCEPTED = 2,
}

/** Enum for splash seen status. */
export enum SplashSeenStatus {
  NOT_SEEN = 0,
  SEEN = 1,
}

/** Enum for file upload error status. */
export enum FileUploadStatusCode {
  SUBMITTED = 0,
  UPLOADED = 1,
  CANCELLED = 2,
  ERROR = 3,
  COMPRESSING = 4,
}

/** Enum for when a file upload is interrupted */
export enum FileUploadInterrupted {
  NONE = 0,
  GENERIC = 1,
  SPECIFIC = 2,
}

export enum FileType {
  UNASSIGNED = "unassigned",
  PRODUCT = "product",
  RECEIPT = "receipt",
}

export enum QuantityUpdateAction {
  INCREASE = "increase",
  DECREASE = "decrease",
}

export enum FieldStatus {
  HIDDEN = -1,
  SHOWN = 0,
  REQUIRED = 1,
}

export enum Client {
  AMFAM = 333,
  LEMONADE = 335,
}

export enum DepreciationStatus {
  ENABLED = 1,
  DISABLED = 0,
}

export enum InventoryRequestStatus {
  SUCCESS = "success",
  FAIL = "fail",
}
