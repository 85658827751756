import { Component } from "@angular/core";

import { AppVarsService } from "./services/app-vars.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "customer-portal";

  constructor(private _appVars: AppVarsService) {
    this._appVars.getSecrets();
  }
}
