/**
 * Extracts the last segment of the URL path.
 *
 * @remarks
 * This function splits the current URL path by the "/" character and returns
 * the last segment.
 *
 * @returns The last segment of the URL path as a string.
 */
export function extractHashFromUrl(): string {
  const url: string = window.location.pathname;
  const pathSegments = url.split("/");
  return pathSegments[pathSegments.length - 1];
}
