import { Injectable } from "@angular/core";

import { ErrorLog } from "src/app/models/error-log";
import { GCPError } from "src/app/models/gcp-error";

import { convertTimestampToDate } from "../utils/timestamp";

import { AppVarsService } from "./app-vars.service";
import { SystemInfoService } from "./system-info.service";

/**
 * Service to generate global error.
 */
@Injectable({
  providedIn: "root",
})
export class ErrorGeneratorService {
  private _preventError = false;

  constructor(
    private _appVars: AppVarsService,
    private _systemInfo: SystemInfoService
  ) {}

  /**
   * Handles global errors and logs them.
   *
   * @param error - The error object.
   */
  public generateError(error: Error): ErrorLog {
    this._preventError = false;
    // Check if the error is CORS issue or success message or sent from localhost.

    if (
      (error.message &&
        error.message.includes(this._appVars.secrets.mattermost_host)) ||
      window.location.href.includes("localhost") ||
      error.message.includes("404 OK") ||
      error.message.includes("Missing or insufficient permissions") ||
      error.message.includes("User canceled the upload/download") ||
      error.message.includes(
        "Failed to get document because the client is offline."
      )
    ) {
      this._preventError = true;
    }

    const browserInfo =
      this._systemInfo.browserInfo ?? this._systemInfo.getBrowserInfo();

    const now = new Date();
    const timestamp = {
      seconds: Math.floor(now.getTime() / 1000),
      nanoseconds: now.getMilliseconds() * 1000000,
    };

    const errorLog: ErrorLog = {
      timestamp: timestamp,
      errorName: error.name || null,
      errorMessage: error.message || null,
      errorStack: error.stack || null,
      browserInfo: browserInfo || null,
      url: window.location.href || null,
      clientMetadata: this._appVars?.clientData?.metadata || null,
    };

    return errorLog;
  }

  /**
   * Checking for duplicate errors.
   *
   * @remarks
   * Checks if the error occurred within the past hour and if it's a new error,
   * adds it to the error logs and updates them in Firestore.
   *
   * @param errorLog - The error log object to be checked and added.
   */
  public checkErrorDuplicity(errorLog: ErrorLog): boolean {
    if (this._preventError) {
      return true;
    }

    if (!this._appVars.errorLogs?.error_logs) {
      this._appVars.errorLogs = {
        error_logs: [],
      };
    }

    const newErrorTimestamp =
      errorLog.timestamp instanceof Date
        ? errorLog.timestamp
        : new Date(
            errorLog.timestamp.seconds * 1000 +
              errorLog.timestamp.nanoseconds / 1000000
          );

    const existingError = this._appVars.errorLogs.error_logs.find(
      (log: ErrorLog) => {
        const previousErrorTimestamp =
          log.timestamp instanceof Date
            ? log.timestamp
            : new Date(
                log.timestamp.seconds * 1000 +
                  log.timestamp.nanoseconds / 1000000
              );

        const timeDifference = Math.abs(
          newErrorTimestamp.getTime() - previousErrorTimestamp.getTime()
        );
        return (
          log.errorMessage === errorLog.errorMessage &&
          timeDifference < 60 * 60 * 1000
        );
      }
    );

    if (!existingError) {
      errorLog.timestamp = convertTimestampToDate(errorLog.timestamp);
      this._appVars.errorLogs.error_logs.push(errorLog);
      this._appVars.updateErrorLogsInFirestore();
      return false;
    }

    return true;
  }

  /**
   * Constructs the GCP error object.
   *
   * @param errorLog - The error log object.
   * @returns The GCP error object.
   */
  public getGCPError(errorLog: ErrorLog): GCPError {
    const errorLocation = this._extractErrorLocation(
      new Error(errorLog.errorStack || "")
    );

    const gcpError: GCPError = {
      eventTime: new Date().toISOString(),
      serviceContext: {
        service: this.constructor.name,
        version: "",
        resourceType: "global",
      },
      message: errorLog.errorMessage,
      context: {
        httpRequest: {
          method: "GET",
          url: errorLog.url,
          userAgent: navigator.userAgent,
          referrer: document.referrer,
          responseStatusCode:
            this._extractResponseStatusCode(errorLog.errorMessage) || 500,
          remoteIp: "",
        },
        user: errorLog?.clientMetadata?.username || "",
        reportLocation: {
          filePath: errorLocation.filePath || "Unable to find file path",
          lineNumber: errorLocation.lineNumber || 0,
          functionName:
            errorLocation.functionName || "Unable to find file function name",
        },
        sourceReferences: [
          {
            repository: "vc-bicc-claimant-app",
            revisionId: "",
          },
        ],
      },
    };

    return gcpError;
  }

  /**
   * Extracts information about the location.
   *
   * @remarks
   * This function parses the stack trace of the provided error
   * object to extract the file path, line number, and function
   * name where the error occurred. It then returns this information
   * as an object with the properties `filePath`, `lineNumber`, and `functionName`.
   *
   * @param error - The error object from which to extract the location information.
   * @returns An object containing the extracted location information.
   */
  private _extractErrorLocation(error: Error): {
    filePath: string;
    lineNumber: number;
    functionName: string;
  } {
    let filePath = "";
    let lineNumber = 0;
    let functionName = "";

    const stackTrace = error.stack;
    if (stackTrace) {
      const stackLines = stackTrace.split("\n").map((line) => line.trim());
      for (let i = 0; i < stackLines.length; i++) {
        const stackLine = stackLines[i];

        // Check for Chrome's stack trace format.
        // example: 'at calculateTotal (app.js:123:45)'
        const chromeFormat = /^\s*at\s+([^(\s]+)\s+\((.*):(\d+):(\d+)\)\s*$/;
        const chromeMatch = stackLine.match(chromeFormat);
        if (chromeMatch) {
          functionName = chromeMatch[1];
          filePath = chromeMatch[2];
          lineNumber = parseInt(chromeMatch[3], 10);
          break;
        }

        // Check for lines containing the file path and line number.
        if (stackLine.startsWith("at")) {
          const parts = stackLine.split(" ");
          if (parts.length >= 2) {
            const locationParts = parts[1].split(":");
            if (locationParts.length >= 2) {
              filePath = locationParts.slice(0, -2).join(":");
              lineNumber = parseInt(
                locationParts[locationParts.length - 2],
                10
              );
              break;
            }
          }
        }

        // Check for lines containing the function name.
        if (!functionName && stackLine.includes("@")) {
          const functionNameParts = stackLine.split("@");
          if (functionNameParts.length >= 2) {
            functionName = functionNameParts[0];
          }
        }
      }
    }

    return { filePath, lineNumber, functionName };
  }

  /**
   * Extracts the response status code from the given error message.
   *
   * This function searches for any sequence of 3 digits within the error message,
   * which is typically the response status code of an HTTP error message.
   *
   * @param errorMessage - The error message from which to extract the response status code.
   * @returns The extracted response status code if found, otherwise undefined.
   */
  private _extractResponseStatusCode(errorMessage: string): number | undefined {
    // Matches any sequence of 3 digits.
    const errorCodeRegex = /\b(\d{3})\b/;
    const match = errorMessage.match(errorCodeRegex);
    if (match) {
      return parseInt(match[0], 10);
    }
    return undefined;
  }
}
