import { FieldSettings } from "src/app/models/client-data";

export const defaultFieldSettings: FieldSettings = {
  description: { status: 1, order: 1 },
  files: { status: 0, order: 2 },
  location_id: { status: 0, order: 3 },
  quantity: { status: 1, order: 4 },
  cost: { status: 0, order: 5 },
  total: { status: 0, order: 6 },
  age: { status: 0, order: 7 },
  notes: { status: 0, order: 8 },
};

export const amFamCustomFieldSettings: FieldSettings = {
  description: { status: 1, order: 1 },
  files: { status: 0, order: 2 },
  location_id: { status: 0, order: 3 },
  quantity: { status: 1, order: 4 },
  cost: { status: 1, order: 5 },
  total: { status: 0, order: 6 },
  age: { status: 1, order: 7 },
  notes: { status: 0, order: 8 },
};

export const headerTranslationKeys: { [key: string]: string } = {
  row_id: "bca_table_page_item_number",
  description: "bca_table_page_item_description",
  files: "bca_table_page_attachments",
  location_id: "bca_table_page_location",
  quantity: "bca_table_page_quantity",
  cost: "bca_table_page_cost_estimate_per_item",
  total: "bca_table_page_total",
  age: "bca_table_page_age",
  notes: "bca_table_page_notes",
  actions: "bca_table_page_actions",
  isSelected: "bca_table_page_select",
};
