import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";

import { AppType } from "src/app/enums/app.enum";
import { environment } from "src/environments/environment";

import { PublicHash } from "../models/public-hashes";
import { extractHashFromUrl } from "../utils/url-utils";

import { ErrorHandlerService } from "./error-handler.service";
import { PublicHashesService } from "./public-hashes.service";

@Injectable({ providedIn: "root" })
export class ApplicationStateService {
  public isMobileResolution = window.innerWidth < environment.mobileResolution;
  public isTabletResolution =
    window.innerWidth > environment.mobileResolution &&
    window.innerWidth < environment.tabletResolution;
  private _appType: string = AppType.CUP;

  public showDepreciationDialog = new BehaviorSubject<boolean>(false);

  constructor(
    private _errorHandlerService: ErrorHandlerService,
    private _router: Router,
    private _hashesService: PublicHashesService
  ) {
    const publicHashString = extractHashFromUrl();

    if (
      window.location.href.includes("adp") ||
      (window.location.href.includes("localhost") &&
        environment?.isAdP === true)
    ) {
      this._appType = AppType.ADP;
    }

    if (publicHashString)
      this._hashesService
        .getPublicHashes()
        .doc(publicHashString)
        .valueChanges()
        .subscribe((data: PublicHash) => {
          if (!data) return;

          if (data.cup_depreciation_enabled && !this.isDepreciationEnabled) {
            this.showDepreciationDialog.next(true);
            this._appType = AppType.DEPRECIATION;
            return;
          }

          if (!data.cup_depreciation_enabled) {
            this.showDepreciationDialog.next(false);
            return;
          }
        });
  }

  /**
   * Gets the application's type.
   *
   * @remarks This function retrieves the current value of the
   * application's type.
   *
   * @returns The current application type.
   */
  public get appType(): string {
    sessionStorage.setItem("appType", this._appType);
    return this._appType;
  }

  public set appType(value: string) {
    this._appType = value;
  }

  /**
   * Checks if the depreciation feature is enabled.
   *
   * @returns A boolean indicating if the depreciation feature is enabled.
   */
  public get isDepreciationEnabled(): boolean {
    return this.appType === AppType.DEPRECIATION;
  }

  /**
   * Sets the application's view to the desktop mode.
   *
   * @remarks This function updates the view mode to "desktop"
   * in session storage.
   */
  public switchToDesktop(): void {
    sessionStorage.setItem("is_desktop_view", "true");
    const hash = extractHashFromUrl();

    this._router
      .navigate(["/app/", hash])
      .then(() => location.reload())
      .catch((error: Error) => this._errorHandlerService.handleError(error));
  }

  /**
   * Sets the application's view to the mobile mode.
   *
   * @remarks This function removes the "desktop" view
   * mode from session storage.
   */
  public switchToMobile(): void {
    sessionStorage.removeItem("is_desktop_view");
    location.reload();
  }
}
