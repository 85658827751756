import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { MessageContent } from "src/app/models/message-content";

@Component({
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.css"],
})
export class MessageComponent {
  private readonly MESSAGE_CONTENTS: MessageContent[] = [
    {
      key: "client-reference",
      title: "bca_client_reference_not_found",
      text: "bca_go_back_easily",
      icon: "error",
    },
    {
      key: "broken-link",
      title: "bca_broken_link_title",
      text: "bca_broken_link_text",
      icon: "broken-link",
    },
  ];

  public pageContent: MessageContent[] = [];

  constructor(private _route: ActivatedRoute) {}

  ngOnInit(): void {
    const messageKey =
      this._route.snapshot.routeConfig?.path === "error/broken-link"
        ? "broken-link"
        : "client-reference";

    this.pageContent = this.MESSAGE_CONTENTS.filter(
      (content) => content.key === messageKey
    );
  }
}
