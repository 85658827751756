import { AngularFirestore } from "@angular/fire/compat/firestore";
import { TranslateLoader } from "@ngx-translate/core";
import { Observable, map } from "rxjs";

import { swapClientTranslations } from "../utils/translation-resolver";

export class CustomTranslateLoader implements TranslateLoader {
  constructor(private firestore: AngularFirestore) {}

  /**
   * Get Translations from firebase.
   *
   * @param lang - Language code chosen by the user.
   * @returns - Observable containing translations.
   */
  public getTranslation(lang = "en"): Observable<{ [key: string]: string }> {
    return this.firestore
      .collection("translations")
      .doc(lang)
      .valueChanges()
      .pipe(
        map((data: { [key: string]: string }) => {
          const clientId = sessionStorage.getItem("client_id");
          const swappedTranslations = swapClientTranslations(data, clientId);
          return swappedTranslations;
        })
      );
  }
}
