import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { GCPError } from "src/app/models/gcp-error";
import { environment } from "src/environments/environment";

import { GCPLogRequest } from "../models/gcp-log-entry";
import { ActionLogEntry } from "../models/session-replay";

import { AppVarsService } from "./app-vars.service";
import { SystemInfoService } from "./system-info.service";

/**
 * Service for sending Google Cloud Platform (GCP) notifications.
 */
@Injectable({ providedIn: "root" })
export class GCPService {
  constructor(
    private _appVars: AppVarsService,
    private _http: HttpClient,
    private _systemInfo: SystemInfoService
  ) {}

  /**
   * Sends a GCP notification with the provided error object.
   *
   * @param error - The GCPError object containing information about the error.
   * @returns An Observable of the HTTP POST request to the GCP endpoint.
   */
  public sendGCPNotification(error: GCPError) {
    const gcpURL = environment.gcpEndpoint + "events:report";
    const apiKey = this._appVars.secrets.gcp_api_key;
    const params = new HttpParams().set("key", apiKey);

    return this._http.post(gcpURL, error, { params });
  }

  /**
   * Sends a Google Cloud Platform (GCP) action log notice.
   *
   * @remarks
   * This method creates a GCP log entry with the provided old and new
   * inventory values, the event name, and some additional information like
   * browser info, timestamp, and the current URL. The log entry is then sent
   * as a POST request to a specified URL.
   *
   * @returns An Observable of the HTTP request.
   * @param logEntry - The ActionLogEntry object containing information about
   * the action happened.
   */
  public sendGCPActionLogNotice(logEntry: ActionLogEntry): Observable<any> {
    const vcApiLogsEndpoint = environment.actionLogsCFUrl;
    const browserInfo =
      this._systemInfo.browserInfo ?? this._systemInfo.getBrowserInfo();

    const gcpLogEntry: ActionLogEntry = {
      action: logEntry.action,
      changes: logEntry.changes,
      browser_info: browserInfo,
      //CHANGE THIS ON EACH RELEASE
      message: `${logEntry.action} @ ${this._appVars.clientReferenceHash} - v${environment.version}`,
      timestamp: new Date(),
      url: window.location.href,
      user_info: logEntry.user_info,
      row_id: logEntry?.row_id,
      inserted_pin: logEntry?.inserted_pin,
      image_url: logEntry?.image_url,
    };

    const gcp_log_request: GCPLogRequest = {
      gcp_project_id: environment.gcpProjectId,
      gcp_logger_client: environment.gcpLoggerClient,
      severity: "NOTICE",
      json_payload: gcpLogEntry,
    };

    return this._http.post(
      vcApiLogsEndpoint,
      {
        gcp_log_request,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `apiKey ${environment.cfApiKey}`,
        },
      }
    );
  }
}
