import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "src/environments/environment";

import { EnabledClientFeaturesResponse } from "../models/feature-access-response";

@Injectable({
  providedIn: "root",
})
export class ClientFeatureService {
  constructor(private http: HttpClient) {}

  /**
   * Checks if the CuP feature is enabled for a specific client.
   *
   * @param clientId - The ID of the client to check feature access for
   * @param apiKey - The API key used for authorization
   * @returns An Observable that emits a FeatureAccessResponse
   * indicating if CuP is enabled
   *
   * @remarks
   * Makes a GET request to the feature access endpoint with the
   * CUP_ENABLED feature ID. The response contains a boolean 'enabled'
   * flag for the requested feature.
   */
  public isCupEnabled(
    clientId: number,
    apiKey: string
  ): Observable<EnabledClientFeaturesResponse> {
    const headers = new HttpHeaders().set("authorization", `apiKey ${apiKey}`);

    return this.http.get<EnabledClientFeaturesResponse>(
      `${environment.apiHost}/clients/${clientId}/settings`,
      { headers }
    );
  }
}
