<h3 class="error-title">
  {{ pageContent[0].title | translate }}
</h3>
<img
  [src]="'assets/icons/' + pageContent[0].icon + '.svg'"
  alt="Error icon"
  class="error-icon"
/>
<p class="error-text">
  {{ pageContent[0].text | translate }}
</p>
