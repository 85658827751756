import { ResolveFn, Route, Routes } from "@angular/router";

import { TranslationEntry } from "../models/translation-entry";
import { MessageComponent } from "../modules/shared/pages/message/message.component";
import { mapToResolve } from "../utils/functional-guards";
import { TranslationResolver } from "../utils/translation-resolver";

export interface CommonRoutes {
  hashRedirect: Route;
  translationResolver: {
    resolve: {
      resolveTranslation: ResolveFn<TranslationEntry>;
    };
  };
  errorRoutes: Route[];
}

export const commonRoutes: CommonRoutes = {
  hashRedirect: {
    path: ":hash",
    redirectTo: "app/:hash",
    pathMatch: "full",
  } as Route,
  translationResolver: {
    resolve: {
      resolveTranslation: mapToResolve(TranslationResolver),
    },
  },
  errorRoutes: [
    {
      path: "error/broken-link",
      pathMatch: "full",
      component: MessageComponent,
    },
    {
      path: "error/client-reference",
      pathMatch: "full",
      component: MessageComponent,
    },
    {
      path: "",
      redirectTo: "error/client-reference",
      pathMatch: "full",
    },
  ],
};

export const desktopRoutes: Routes = [
  commonRoutes.hashRedirect,
  {
    path: "",
    ...commonRoutes.translationResolver,
    loadChildren: () =>
      import("../modules/claimant/claimant.module").then(
        (m) => m.ClaimantModule
      ),
  },
  ...commonRoutes.errorRoutes,
  {
    path: "**",
    redirectTo: "error/client-reference",
  },
];

export const mobileRoutes: Routes = [
  commonRoutes.hashRedirect,
  {
    path: "",
    ...commonRoutes.translationResolver,
    loadChildren: () =>
      import("../modules/claimant-mobile/claimant-mobile.module").then(
        (m) => m.ClaimantMobileModule
      ),
  },
  ...commonRoutes.errorRoutes,
  {
    path: "**",
    redirectTo: "error/client-reference",
  },
];
