/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectorRef,
  ElementRef,
  Injectable,
  Injector,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import * as firebase from "firebase/compat/app";
import { Subject, Subscription, firstValueFrom } from "rxjs";

import { AppData, Secrets } from "src/app/models/app-data";
import { ClientData } from "src/app/models/client-data";
import { ClientReference } from "src/app/models/client-references";
import { ErrorLogs } from "src/app/models/error-log";
import { Inventory } from "src/app/models/inventory";
import { InputMaskOptions } from "src/app/models/mask-options";
import { PublicHash } from "src/app/models/public-hashes";

import {
  amFamCustomFieldSettings,
  defaultFieldSettings,
  depreciationFieldSettings,
} from "../constants/field-settings";
import {
  AppDataType,
  AppType,
  Client,
  ClientReferenceStatus,
  DepreciationStatus,
  FieldStatus,
  OperationType,
  QuantityUpdateAction,
} from "../enums/app.enum";
import { ClientFeatureID } from "../enums/client-feature.enum";

import { AppDataService } from "./app-data.service";
import { ApplicationStateService } from "./application-state.service";
import { ClientDataService } from "./client-data.service";
import { ClientFeatureService } from "./client-feature.service";
import { ClientReferencesService } from "./client-references.service";
import { ClientStylesService } from "./client-styles.service";
import { CrudLogService } from "./crud-log.service";
import { ErrorHandlerService } from "./error-handler.service";
import { LogsService } from "./logs.service";
import { PublicHashesService } from "./public-hashes.service";
import { RedirectService } from "./redirect.service";

@Injectable({ providedIn: "root" })
export class AppVarsService {
  public clientId: number;
  public clientReferenceHash: string;
  public clientReferenceHashRegex = /^[a-z0-9-]{6,8}$/i;
  public accessCode: string;
  public ADPaccessCode: string;
  public activeRow: Inventory;
  public appData: AppData;
  public clientData: ClientData;
  public clientReference: ClientReference;

  public inputHashChecked = false;
  public allDataLoaded = false;
  public clientReferenceChecked = false;

  public categorySelected = new Subject();

  public imageTypeRegex =
    /image\/(a?png|avif|bmp|gif|x-icon|cur|jpeg|tiff|svg+xml|webp)/;
  public imageExtRegex = /\.(a?png|avif|bmp|gif|ico|cur|jpe?g|tiff?|svg|webp)$/;
  public pdfTypeRegex = /([a-zA-Z0-9\s_\\.])+(.pdf)$/i;

  language = "en";
  languageList = new Map([
    ["de", "Deutsch"],
    ["en", "English"],
    ["nl", "Nederlands"],
    ["es", "Español"],
    ["no", "Norsk"],
    ["fr", "Français"],
  ]);

  public bcaStatus: number;
  public isChromeBrowser: boolean;
  public isTableLocked: boolean;
  public submitLockTable = false;
  public operationTypeValue: OperationType;
  public submitClientReferenceStatus: boolean;
  public lastItemAddedStatus: boolean;
  public lastItemUpdated: number | null = null;
  public lastLocationUpdated: string;
  public secrets: Secrets;
  public errorLogs: ErrorLogs;
  public dataSource = new MatTableDataSource<Inventory>();
  public isInventoryPageLoading = false;

  // Currency mask options.
  public currencyInputMask: InputMaskOptions = {
    alias: "currency",
    groupSeparator: ",",
    digits: 2,
    digitsOptional: false,
    prefix: "",
    placeholder: "0",
    allowMinus: false,
    parser: (value: string) => {
      return parseFloat(value.replace(/,/g, ""));
    },
  };

  constructor(
    public translate: TranslateService,
    private _appDataService: AppDataService,
    private _clientDataService: ClientDataService,
    private _clientReferencesService: ClientReferencesService,
    private _hashesService: PublicHashesService,
    private _injector: Injector,
    private _logsService: LogsService,
    private _clientStylesService: ClientStylesService,
    private _clientFeatureService: ClientFeatureService
  ) {}

  public resetData() {
    this.clientId = undefined;
    this.clientReferenceHash = undefined;
    this.accessCode = undefined;
    this.ADPaccessCode = undefined;
    this.appData = undefined;
    this.clientData = undefined;
    this.clientReference = undefined;
    this.inputHashChecked = false;
    this.allDataLoaded = false;
    this.clientReferenceChecked = false;
  }

  /**
   * Get the max display id of current rows.
   *
   * @remarks
   * The max display id will be used to  calculate the display id of a new row.
   * If clientReference is not available, then returns the initial value
   * of maxDisplayId.
   * @returns Max display id for current rows.
   */
  public getMaxDisplayId(): number {
    let claimData: Inventory[] | MatTableDataSource<Inventory>;

    const appState = this._injector.get(ApplicationStateService);
    if (!appState.isMobileResolution && !appState.isTabletResolution) {
      claimData = this.dataSource.data || [];
    } else {
      claimData = this.clientReference.claim_data;
    }

    let maxDisplayId = 0;

    if (!claimData) {
      this.dataSource.data = [];
      this.clientReference.claim_data = [];
      return maxDisplayId;
    }

    claimData.forEach((row) => {
      if (row.display_id > maxDisplayId) {
        maxDisplayId = row.display_id;
      }
    });

    return maxDisplayId;
  }

  /** Adjust the display ids to make them sequential for display. */
  public adjustDisplayIds() {
    let idx = 1;
    let claimData: Inventory[] | MatTableDataSource<Inventory>;

    const appState = this._injector.get(ApplicationStateService);
    if (!appState.isMobileResolution && !appState.isTabletResolution) {
      claimData = this.dataSource.data || [];
    } else {
      claimData = this.clientReference.claim_data;
    }

    claimData.reverse().forEach((row) => {
      row.display_id = idx;
      idx++;
    });
  }

  /**
   * Calculates and returns the maximum row ID from the claim data.
   *
   * @remarks
   * This function first checks if the claim data exists in the client reference object.
   * If it does not exist, an empty array is used as a fallback.
   *
   * It then uses the `reduce` function to find the maximum row ID in the claim data array.
   * If the claim data array is empty, the initial value of 0 is used.
   *
   * Finally, it returns the maximum of the maximum row ID found in the claim data array
   * and the `max_row_id` in the metadata of the client reference object.
   * If `max_row_id` does not exist, 0 is used as a fallback.
   *
   * @returns The maximum row ID.
   */
  public getMaxRowId(): number {
    let claimData: Inventory[] | MatTableDataSource<Inventory>;

    const appState = this._injector.get(ApplicationStateService);
    if (appState.isMobileResolution && appState.isTabletResolution) {
      claimData = this.dataSource.data || [];
    } else {
      claimData = this.clientReference.claim_data;
    }
    const maxRowIdInArray = claimData.reduce(
      (max, row) => Math.max(max, row.row_id),
      0
    );
    return Math.max(
      maxRowIdInArray,
      this.clientReference?.metadata?.max_row_id || 0
    );
  }

  /**
   * Create a new row with default values.
   *
   * @returns A new row created with default values.
   */
  public createNewRow(): Inventory {
    const time = new Date();
    const appType = sessionStorage.getItem("appType");

    const baseRow: Partial<Inventory> = {
      display_id: this.getMaxDisplayId() + 1,
      row_id: this.getMaxRowId() + 1,
      client_category: "",
      location_id: "",
      quantity: 1,
      description: "",
      condition: 2,
      cost: 0.0,
      total: 0,
      age: "",
      notes: "",
      files: [],
      create_time: time,
      update_time: time,
      populatedFields: [],
      nonMatchingFields: [],
    };

    if (Number.isNaN(baseRow.row_id)) {
      baseRow.row_id = this.getMaxRowId() + 1;
    }

    if (appType === "AdP") {
      baseRow.replacement_product = {
        rpid: 0,
        replacement_cost_value: 0.0,
        rp_name_cart: "",
        rp_pic_url: "",
        rp_price_date: "",
        selected_client_category_id: 0,
        shop_name: "",
        shop_url: "",
      };
    }

    const newRow = baseRow as Inventory;
    this.clientReference.metadata.max_row_id = newRow.row_id;
    return newRow;
  }

  /** Get all the common data for the application. */
  async getAllAppData() {
    const crudLog = this._injector.get(CrudLogService);

    if (!this.allDataLoaded) {
      const subscription = new Subscription();

      subscription.add(this.getSecrets());
      subscription.add(crudLog.getSessionReplays());
      subscription.add(this.getAppData());
      subscription.add(this.getClientReference());
      subscription.add(this.getErrorLogs());

      await this.waitForOneAppData(AppDataType.CLIENT_REFERENCE);
      subscription.add(this.getClientData());

      await this.waitForAllAppData();
      subscription.unsubscribe();
      this.allDataLoaded = true;
    }
  }

  /**
   * Get the public hash from Firestore.
   *
   * @param hash - The hash passed from the URL.
   */
  public async checkHashInFirestore(
    hash: string
  ): Promise<Subscription | void> {
    const redirectService = this._injector.get(RedirectService);

    return new Promise((resolve) => {
      const hashSubscription = this._hashesService
        .getPublicHashes()
        .doc(hash)
        .valueChanges()
        .subscribe((data: PublicHash) => {
          this.inputHashChecked = true;
          if (data) {
            this.clientReferenceHash = hash;
            this.clientId = Number(data.client_id);
            this.accessCode = data.access_code;
            this.ADPaccessCode = data.adp_access_code;

            sessionStorage.setItem("client_id", this.clientId.toString());
            sessionStorage.setItem("public_hash", this.clientReferenceHash);
            // Reset translations needed for client-specific translations.
            this.resetLanguage();
          }
          resolve(hashSubscription);
        });
    });
  }

  /**
   * Resets the current language translations for the application.
   *
   * This method retrieves the translations for the current language set in the application,
   * or defaults to the stored language if the current language is not set. Once the translations
   * are retrieved, they are used to update the translation configuration.
   *
   * This function is typically called when a language change or reset is required, ensuring that
   * the latest translations are applied for the active language.
   *
   * @remarks
   * - If no current language is set, the method falls back to the default stored language.
   * - The method leverages `translate.setTranslation` to overwrite any existing translations
   *   for the current language.
   *
   */
  public resetLanguage(): void {
    const language = this.translate.currentLang ?? this.language;
    this.translate
      .getTranslation(language)
      .subscribe((translations: { [key: string]: string }) => {
        if (translations)
          this.translate.setTranslation(
            this.translate.currentLang,
            translations
          );
      });
  }

  /**
   * Get the app data from Firestore.
   *
   * @remarks
   * The app data is the application-wise data and not for a specific client
   * or client reference.
   *
   * @returns The subscription to get the app data.
   */
  public getAppData(): Subscription {
    if (this.appData) {
      return this.getDummySubscription();
    }

    return this._appDataService.getAppData().subscribe((data: AppData) => {
      this.appData = data;
    });
  }

  /**
   * Get the secrets from Firestore.
   *
   * @returns The subscription to get the secrets.
   */
  public getSecrets(): Subscription {
    if (this.secrets) {
      return this.getDummySubscription();
    }

    const secretsSubscription = this._appDataService
      .getSecrets()
      .subscribe((data: Secrets) => {
        this.secrets = data;
      });

    return secretsSubscription;
  }

  /**
   * Get the client reference data from Firestore.
   *
   * @remarks
   * The inventory data entered by a user is stored as a document in a
   * client_reference Firestore collection.
   *
   * @returns The subscription to get the client reference.
   */
  public getClientReference(): Subscription {
    if (this.clientReference) {
      return this.getDummySubscription();
    }

    return this._clientReferencesService
      .getClientReferences()
      .doc(this.clientReferenceHash)
      .valueChanges()
      .subscribe((data: ClientReference) => {
        this.clientReferenceChecked = true;

        if (data) {
          this._updateTableLockStatus(data.metadata.client_reference_status);
          this.clientReference = this._ensureClientReferenceFields(data);

          if (this.dataSource.data === data.claim_data || !this.activeRow) {
            this.dataSource = new MatTableDataSource(
              this.clientReference.claim_data.slice().reverse()
            );
          }
        }

        if (data && !data.claim_data) {
          this.clientReference.claim_data = [];
        }
      });
  }

  /**
   * Ensure that the client reference object has all required fields.
   *
   * @param data - The client reference data from Firestore.
   * @returns The client reference data with all required fields.
   */
  private _ensureClientReferenceFields(data: ClientReference): ClientReference {
    return {
      claim_data: data.claim_data || [],
      metadata: {
        client_id: data.metadata.client_id || 0,
        client_reference: data.metadata.client_reference || "",
        client_reference_id: data.metadata.client_reference_id || 0,
        client_reference_status: data.metadata.client_reference_status || 0,
        create_time: data.metadata.create_time || new Date(),
        hash: data.metadata.hash || "",
        splash_seen: data.metadata.splash_seen || 0,
        privacy_policy_status: data.metadata.privacy_policy_status || 0,
        update_time: data.metadata.update_time || new Date(),
        max_row_id: data.metadata.max_row_id || 0,
        username: data.metadata.username || "",
        depreciation_status: data.metadata.depreciation_status || 0,
        support_id: data.metadata.support_id || 0,
      },
      locations: data.locations || [],
    };
  }

  /**
   * Get the client data from Firestore.
   *
   * @returns The subscription to get the client data.
   */
  public getClientData(): Subscription {
    const clientFeatureService = this._injector.get(ClientFeatureService);
    const redirectService = this._injector.get(RedirectService);

    if (!this.clientReference) {
      // Handle the case when this.clientReference is not defined.
      return this.getDummySubscription();
    }

    return this._clientDataService
      .getClientData()
      .doc(this.clientReference?.metadata?.client_id?.toString())
      .valueChanges()
      .subscribe((data: ClientData) => {
        this.clientData = data;
        this.language =
          sessionStorage.getItem("language") ??
          this.clientData?.metadata?.language;
        this.translate.use(this.language);

        // Reset translations needed for client-specific translations.
        this.resetLanguage();

        // Add feature check here with proper error handling.
        void firstValueFrom(
          clientFeatureService.isCupEnabled(
            data.metadata.client_id,
            data.metadata.apiKey
          )
        )
          .then((clientFeatureData) => {
            const response = clientFeatureData;
            const isCupEnabled = response.enabled_client_features.some(
              (featureId) => featureId === ClientFeatureID.CUP_ENABLED
            );

            if (!isCupEnabled) {
              redirectService.redirectOnError("error/broken-link");
              return;
            }

            if (data?.metadata) {
              this._clientStylesService.handleClientStyles(
                data.metadata.client_id
              );
            }
          })
          .catch((error: HttpErrorResponse) => {
            if (error.status === 401)
              redirectService.redirectOnError("error/broken-link");
            else this.handleError(error);
            return;
          });

        if (data?.metadata)
          this._clientStylesService.handleClientStyles(data.metadata.client_id);
      });
  }

  /** Wait for the public hash be loaded from Firestore. */
  public async waitForPublicHash() {
    let k = 0;
    while (!this.clientReferenceHash && !this.inputHashChecked) {
      await this.delay(200);
      k++;
      if (k > 50) {
        break;
      }
    }
  }

  /**
   * Wait for one specific type of data to be loaded from Firestore.
   *
   * @param dataType - The data type to be waited for.
   */
  public async waitForOneAppData(dataType: AppDataType) {
    let k = 0;
    while (!this[dataType]) {
      if (
        dataType == AppDataType.CLIENT_REFERENCE &&
        this.clientReferenceChecked
      ) {
        break;
      }

      await this.delay(200);
      k++;
      if (k > 50) {
        break;
      }
    }
  }

  /** Wait for all app data be loaded from Firestore. */
  public async waitForAllAppData() {
    let k = 0;
    while (
      !this.appData ||
      !this.clientData ||
      (!this.clientReference && !this.clientReferenceChecked)
    ) {
      await this.delay(200);
      k++;
      if (k > 50) {
        break;
      }
    }
    this.allDataLoaded = true;
  }

  /**
   * A helper function that can be used to block the execution of code.
   *
   * @remarks
   * This function is usually used to wait for the response from an API so
   * necessary data can be obtained for further processing of the code.
   *
   * @param ms - Milliseconds to delay.
   * @returns A promise that can be waited for.
   */
  public delay(ms: number): Promise<void> {
    return new Promise((f) => setTimeout(f, ms));
  }

  /**
   * Get a dummy subscription.
   *
   * @remarks
   * The dummy subscription is used to make the methods to get app data easier
   * to use. Otherwise, we would need to check if a subscription is returned
   * or not.
   *
   * @returns A dummy subscription.
   */
  public getDummySubscription(): Subscription {
    const dummySubject = new Subject();
    dummySubject.next(null);
    return dummySubject.subscribe();
  }

  /**
   * Check if the hash in the url is correct.
   *
   * @param hash - The hash passed from the URL.
   */
  public async checkUrlHash(hash: string): Promise<void> {
    const redirectService = this._injector.get(RedirectService);

    try {
      await this.checkHashInFirestore(hash);

      if (hash === "" || hash === "undefined") {
        redirectService.redirectOnError();
        return;
      }

      if (!this.clientReferenceHashRegex.test(hash)) {
        redirectService.redirectOnError();
        return;
      }

      if (hash !== this.clientReferenceHash) {
        this.resetData();
      }

      if (!this.clientReferenceHash) {
        redirectService.redirectOnError();
      }
    } catch (error) {
      if (error instanceof Error) {
        this.handleError(error);
      }
      redirectService.redirectOnError();
    }
  }

  /**
   * A helper function for translation by keys.
   *
   * @remarks
   * This function is used for translation, and it checks whether the parameter
   * is translation key and translate it. If not then returns a key.
   *
   * @param key - The translation key string.
   * @returns Either translation or key.
   */
  public async getTranslationByKey(key: string): Promise<string> {
    // This is required since ngx-translate is a lazy-loaded module, and
    // it is loaded differently. Hence, it is necessary to mention
    // which language to use when we are using ngx-translate in service.
    this.translate.use(this.language);
    return (await firstValueFrom(this.translate.get(key))) as string;
  }

  /**
   * Return the localeString using language code and country code.
   *
   * @remarks
   * If language is null, then it is assumed to be english ('en'). Similarly,
   * if country code is null then it is assumed to be US.
   *
   * @returns Locale string by concatenating language code and country code with
   *  dash. Eg: sv-SE
   */
  public getLocale() {
    let language =
      sessionStorage.getItem("language") ?? this.clientData.metadata.language;
    let country = this.clientData.metadata.country;

    country === "uk" ? (country = "gb") : "";
    language === "us" ? (language = "en") : "";

    language = language ? language.trim() : "en";
    country = country ? country.trim().toUpperCase() : "US";
    return language + "-" + country;
  }

  /**
   * Sign in the user anonymously using Firebase authentication.
   *
   * @returns A promise authentication with Firebase anonymously.
   */
  public async signInAnonymously() {
    return firebase.default
      .auth()
      .signInAnonymously()
      .catch((error: Error) => this.handleError(error));
  }

  /**
   * Function for updating client reference data in Firestore.
   *
   * @remarks
   * This function is used to add, remove or edit an entire client reference.
   * submitClientReferenceStatus is set to true when the client reference
   * is submitted to indicate which location is chosen in mobile version
   * in locations-list page. Indicator lasts for 5 seconds then it is hidden.
   */
  public updateClientReferenceInFirestore(): void {
    const appState = this._injector.get(ApplicationStateService);
    let data: Inventory[] = [];
    if (!appState.isMobileResolution && !appState.isTabletResolution) {
      data = this.dataSource.data;
      // Function to check if data is sorted in ascending order based on display_id.
      const isSortedAscending = (arr: Inventory[]): boolean => {
        for (let i = 1; i < arr.length; i++) {
          if (arr[i - 1].display_id > arr[i].display_id) {
            return false;
          }
        }
        return true;
      };

      // Check if the data is sorted in ascending order based on display_id.
      if (!isSortedAscending(data)) {
        // Sort the data in ascending order based on display_id.
        data.sort((a, b) => a.display_id - b.display_id);
      }
    } else {
      data = this.clientReference.claim_data;
    }

    this._clientReferencesService
      .getClientReferences()
      .doc(this.clientReferenceHash)
      .update({
        claim_data: data,
        locations: this.clientReference.locations,
        "metadata.update_time": new Date(),
        "metadata.max_row_id": this.clientReference.metadata.max_row_id ?? 0,
      })
      .then(() => {
        this.submitClientReferenceStatus = true;
      })
      .catch((error: Error) => this.handleError(error));

    // Remove indicator after 3 seconds.
    setTimeout(() => {
      this.submitClientReferenceStatus = false;
    }, 3000);
  }

  /** Get operation type. */
  public get operationType() {
    return OperationType;
  }

  /** Get app type. */
  public get appType() {
    return AppType;
  }

  /** Get Quantity Update Action type. */
  public get quantityUpdateAction() {
    return QuantityUpdateAction;
  }

  /** Get depreciation status. */
  public get depreciationStatus() {
    return DepreciationStatus;
  }

  /**
   * Updates inventory locking/unlocking related variables.
   *
   * @remarks
   * Updates the bcaStatus, isTableLocked, and operationTypeValue variables
   * based on the client reference metadata.
   *
   * @param clientReferenceStatus - The status of the client reference.
   */
  private _updateTableLockStatus(
    clientReferenceStatus: ClientReferenceStatus
  ): void {
    this.isTableLocked = clientReferenceStatus === ClientReferenceStatus.CLOSED;
  }

  /**
   * Checks if the current browser is Chrome.
   *
   * @remarks
   * This function checks if utilized browser is Chrome and based on that
   * condition it will update the local variable isChromeBrowser.
   */
  public checkIfChromeBrowser() {
    this.isChromeBrowser = window.navigator.userAgent
      .toLowerCase()
      .includes("chrome");
  }

  /**
   * Calculates the total sum of inventory items.
   *
   * @remarks
   * This function iterates through the claim data and computes
   * the sum of the 'total' property for each item, providing the total
   * value of all inventory items.
   *
   * @returns {number} The total sum of inventory items.
   */
  public get sumInventoryItems(): number {
    return this.clientReference.claim_data.reduce(
      (sum, item) => sum + (item.total || 0),
      0
    );
  }

  /**
   * Submits the inventory data to Firestore and updates the client reference status.
   *
   * @remarks
   * This function performs the following actions:
   * 1. Retrieves client references from the `_clientReferencesService`.
   * 2. Updates the `metadata.client_reference_status` field to
   * `ClientReferenceStatus.SUBMITTED` or another provided status in Firestore.
   * 3. Displays an error message in a snackbar in case of an error during submission.
   */
  public async submitInventoryToFirestore(
    clientReferenceStatus: ClientReferenceStatus
  ): Promise<void> {
    try {
      await this._clientReferencesService
        .getClientReferences()
        .doc(this.clientReferenceHash)
        .update({
          "metadata.client_reference_status": clientReferenceStatus,
        });
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.handleError(error);
      }
    }
  }

  /** Retrieves a translation and sets it as the innerHTML.
   *
   * @remarks
   * This method uses the provided translation key to fetch the translation
   * content.The translated content is then set into the innerHTML of the
   * element referenced by the given ElementRef. After setting the innerHTML,
   * change detection is manually triggered to ensure immediate rendering updates.
   *
   * @param key - The translation key used to retrieve the translation content.
   * @param elementRef - The ElementRef referencing the DOM element where the
   * translated content will be displayed.
   * @param cdr - The ChangeDetectorRef used to manually trigger change detection
   * after setting the innerHTML.
   *
   * @returns A Promise that resolves with the modified innerHTML after applying
   * the translation.
   *
   * @example
   * ```typescript
   * const key = 'bca_desktop_terms_html';
   * const elementRef = this.termsContentInnerHtml;
   * const cdr = this.changeDetectorRef;
   * await this.getTranslatedInnerHtml(key, elementRef, cdr);
   * ```
   */
  public async getTranslatedInnerHtml(
    key: string,
    elementRef: ElementRef,
    cdr: ChangeDetectorRef
  ): Promise<string> {
    if (key === "bca_privacy_compliance_html") {
      return await this.getTranslationByKey(key);
    }

    const translation: string = await this.getTranslationByKey(key);
    elementRef.nativeElement.innerHTML += translation;
    cdr.detectChanges();

    return elementRef?.nativeElement.innerHTML as string;
  }

  /**
   * Checks if submit button should be disabled.
   *
   * @remarks
   * This function evaluates whether any required fields are missing in the
   * `activeInventory`. If the `fieldSettings` are available in
   * `client_feature_settings`, it checks for missing required fields. If
   * `fieldSettings` are not available, it checks for the absence or emptiness of
   * the `description` field in the `activeInventory`.
   *
   * @param activeInventory - The active inventory object.
   * @returns A boolean indicating if button should be disabled.
   */
  public isSubmitButtonDisabled(activeInventory: Inventory): boolean {
    const appState = this._injector.get(ApplicationStateService);

    if (appState.isDepreciationEnabled) {
      if (
        !this.isRDRequestedValid(activeInventory) ||
        !this.hasDepreciationDate() ||
        !this.hasRDReceipt()
      )
        return true;
    }

    const isDepreciationEnabled = appState.isDepreciationEnabled;
    const fieldSettings = isDepreciationEnabled
      ? depreciationFieldSettings
      : this.clientData?.client_feature_settings?.field_settings ||
        defaultFieldSettings;

    let isAnyRequiredFieldMissing: boolean;

    if (fieldSettings) {
      // Check if any required field is missing in activeInventory.
      isAnyRequiredFieldMissing = Object.entries(fieldSettings).some(
        ([fieldName, fieldSetting]) => {
          const fieldValue = !!activeInventory?.[fieldName];

          if (
            fieldName === "files" &&
            this.isFieldRequired(fieldSetting as string)
          ) {
            if (!fieldValue) {
              return true;
            }
          }

          // Check if the field is required and its value is missing or falsy.
          return fieldSetting?.status === FieldStatus.REQUIRED && !fieldValue;
        }
      );
    } else {
      isAnyRequiredFieldMissing = !activeInventory?.description?.trim();
    }

    // Determine if the submit button should be disabled.
    return isAnyRequiredFieldMissing;
  }

  /**
   * Checks if the requested recoverable depreciation (RD) is valid.
   *
   * @remarks
   * This function verifies if the `rd_requested` value in the `recoverable_depreciation`
   * object of the `activeRow` is greater than 0. If the value is greater than 0,
   * it returns true, indicating that the requested RD is valid.
   * If the `activeRow` is not available, the function returns true, as there is no
   * active row to validate.
   *
   * @param item - The inventory item to check for RD validity.
   *
   * @returns A boolean indicating if the requested RD is greater than 0.
   *
   */
  public isRDRequestedValid(item: Inventory): boolean {
    if (!this.activeRow) return true;
    if (item?.row_id !== this.activeRow.row_id) return true;
    return this.activeRow?.recoverable_depreciation?.cup?.rd_requested > 0;
  }

  /**
   * Checks if the active row has a depreciation date.
   *
   * @returns A boolean indicating if the active row has a depreciation date.
   *
   * @remarks
   * This function checks if the `purchase_date` property exists in the `recoverable_depreciation`
   * object of the `activeRow`. If the `purchase_date` exists, it returns true; otherwise, it returns false.
   */
  public hasDepreciationDate(): boolean {
    return !!this.activeRow?.recoverable_depreciation?.cup?.purchase_date;
  }

  /**
   * Checks if the active row has a recoverable depreciation receipt.
   */
  public hasRDReceipt(): boolean {
    return !!this.activeRow?.recoverable_depreciation?.cup?.depreciation_receipt
      ?.path;
  }

  /**
   * Checks if a field is marked as required based on the client feature settings.
   *
   * @remarks
   * This function examines the `field_settings` property in
   * `client_feature_settings` and determines if the specified field has a
   * requirement status of `FieldStatus.REQUIRED`. If the field is marked as
   * required, the function returns `true`; otherwise, it returns `false`.
   * @param field - The name of the field.
   * @returns A boolean indicating if the field is required.
   */
  public isFieldRequired(field: string): boolean {
    const appState = this._injector.get(ApplicationStateService);

    const isDepreciationEnabled = appState.isDepreciationEnabled;
    let fieldSettings = isDepreciationEnabled
      ? depreciationFieldSettings
      : this.clientData?.client_feature_settings?.field_settings ||
        defaultFieldSettings;

    if (
      fieldSettings === defaultFieldSettings &&
      this.clientData.metadata.client_id === Client.AMFAM
    ) {
      fieldSettings = amFamCustomFieldSettings;
    }

    return fieldSettings[field]?.status === FieldStatus.REQUIRED || false; // Return the status or false if not found
  }

  /**
   * Retrieves error logs from the logs service.
   *
   * @returns A subscription object representing the ongoing subscription to the error logs.
   */
  public getErrorLogs(): Subscription {
    return this._logsService
      .getErrorLogs()
      .doc(this.clientReferenceHash)
      .valueChanges()
      .subscribe((data: ErrorLogs) => {
        this.errorLogs = data;
      });
  }

  /** Updates error logs in Firestore. */
  public updateErrorLogsInFirestore(): void {
    this._logsService
      .getErrorLogs()
      .doc(this.clientReferenceHash)
      .set(this.errorLogs, { merge: true })
      .catch((error: Error) => this.handleError(error));
  }

  /**
   * Handles the given error by passing it to the error handler service.
   *
   * @param error - The error to handle.
   */
  public handleError(error: Error) {
    const errorHandlerService = this._injector.get(ErrorHandlerService);
    errorHandlerService.handleError(error);
  }
}
