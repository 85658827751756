import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { environment } from "src/environments/environment";

import { ErrorHandlerService } from "./error-handler.service";

@Injectable({ providedIn: "root" })
export class ApplicationStateService {
  public isMobileResolution = window.innerWidth < environment.mobileResolution;
  public isTabletResolution =
    window.innerWidth > environment.mobileResolution &&
    window.innerWidth < environment.tabletResolution;
  private _appType = "CuP";

  constructor(
    private _errorHandlerService: ErrorHandlerService,
    private _router: Router
  ) {
    if (
      window.location.href.includes("adp") ||
      (window.location.href.includes("localhost") &&
        environment?.isAdP === true)
    ) {
      this._appType = "AdP";
    }
  }

  /**
   * Gets the application's type.
   *
   * @remarks This function retrieves the current value of the
   * application's type.
   *
   * @returns The current application type.
   */
  public get appType(): string {
    sessionStorage.setItem("appType", this._appType);
    return this._appType;
  }

  /**
   * Sets the application's view to the desktop mode.
   *
   * @remarks This function updates the view mode to "desktop"
   * in session storage.
   */
  public switchToDesktop(): void {
    sessionStorage.setItem("is_desktop_view", "true");
    const hash = sessionStorage.getItem("public_hash");

    this._router
      .navigate(["/app/", hash])
      .then(() => location.reload())
      .catch((error: Error) => this._errorHandlerService.handleError(error));
  }

  /**
   * Sets the application's view to the mobile mode.
   *
   * @remarks This function removes the "desktop" view
   * mode from session storage.
   */
  public switchToMobile(): void {
    sessionStorage.removeItem("is_desktop_view");
    location.reload();
  }
}
